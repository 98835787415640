import { format, subDays } from 'date-fns'
import MobileDetect from 'mobile-detect'

export function isValidUserId(value) {
  const regType = /^[A-Za-z0-9+]{5,20}$/
  return regType.test(value)
}

export function isValidPassword(value) {
  // const regType = /^[a-zA-Z0-9]{6,20}$/
  const regType = /^.{6,20}$/
  return regType.test(value)
}

export function isValidOnlyNumber(value) {
  const regType = /^[0-9]+$/
  return regType.test(value)
}

export function isValidOnlyPhoneNumber(value) {
  const regType = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/
  return regType.test(value)
}
export function isPlainObject(value) {
  return Object.prototype.toString.call(value) === '[object Object]'
}

export function isInteger(value) {
  return typeof value === 'number' && isFinite(value) && Math.floor(value) === value
}

export function upperFirst(value) {
  value = String(value)

  if (!value.length) {
    return value
  }
  return value.charAt(0).toUpperCase() + value.slice(1)
}

export function cloneDeep(data) {
  return JSON.parse(JSON.stringify(data))
}

export function getPercentage(value, total, digits) {
  if (isNaN(value) || isNaN(total) || total === 0) {
    return 0
  }

  let result = value / total * 100

  if (digits !== undefined) {
    result = Number(result.toFixed(digits))
  }
  return result
}

export function getTimeString(value) {
  const hour = Math.floor(value / 3600)
  const minute = Math.floor(value / 60) % 60
  const second = Math.round(value % 60)
  if (hour >= 1) {
    return `${hour}h ${minute}m ${second}s`
  }
  if (minute >= 1) {
    return `${minute}m ${second}s`
  }
  return `${second}s`
}

export function getNumberWithOrdinal(n) {
  const s = ['th', 'st', 'nd', 'rd']
  const v = n % 100
  return n + (s[(v - 20) % 10] || s[v] || s[0])
}

export function thousand(value, fixed = 0) {
  value = Number(value)
  return value.toLocaleString('en-US', { minimumFractionDigits: fixed, maximumFractionDigits: fixed })
}

export function leadingZero(number) {
  return number < 10 ? '0' + number : number
}

export function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function getDateStr(value, defaultStr) {
  const type = typeof value
  if(type === 'string') {
    value = new Date(value)
  }
  if (!defaultStr) {
    defaultStr = 'yyyy-MM-dd'
  }

  return format(value, defaultStr)
}

export function getDateStrKorean(value, defaultStr, type) {
  if (!defaultStr) {
    defaultStr = 'MM월 dd일'
  }

  return format(value, defaultStr)
}

export function getSubDaysDate(value, days) {
  return subDays(value, days)
}

export function isMobile() {
  const md = new MobileDetect(window.navigator.userAgent)
  return md.mobile()
}

function b64DecodeUnicode(str) {
  return decodeURIComponent(
    Array.prototype.map.call(atob(str), c =>
      '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
    ).join(''))
}

export function parseJwt(token) {
  return JSON.parse(
    b64DecodeUnicode(
      token.split('.')[1].replace('-', '+').replace('_', '/')
    )
  )
}

export function convertAnonymous(target, showSize, convertChar) {
  let text = target;
  const regExp = /.*/g;
  const textLength = text.length;
  if(textLength && textLength !== 0) {
    const originText = text.substring(showSize, convertChar)
    const replaceText = text.slice(showSize);
    text = originText + replaceText.replace(regExp, convertChar)
  }

  return text
}

export const listGroupBy = (item, criteria) => {
  return item.reduce((result, target) => {
    result[target[criteria]] = result[target[criteria]] || []
    result[target[criteria]].push(target)
    return result
  }, {})
}

export function imageLoader(src, fileExtension) {
  try {
    let image = ''
    if(fileExtension === 'jpg') {
      image = require(`@/${src}.jpg`)
    }else if(fileExtension === 'png') {
      image = require(`@/${src}.png`)
    }else if(fileExtension === 'svg') {
      image = require(`@/${src}.svg`)
    }else {
      image = require(`@/${src}.png`)
    }
    return image
  }catch (e) {
    console.log(e)
    return ''
  }
}
