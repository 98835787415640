export const board = {
  name: 'board',
  path: '/board',
  redirect: {
    name: 'noticelist'
  },
  meta: {
    layout: 'default',
    text: '게시판',
    gnb: true,
    sub: false
  },
  children: [
    {
      name: 'boardList',
      path: '/board/list',
      component: () => import('@/views/winner/board/list'),
      meta: {
        gnbSub: false
      }
    },
    {
      name: 'boardDetail',
      path: '/board/detail/:boardIdx',
      component: () => import('@/views/winner/board/detail'),
      meta: {
        gnbSub: false
      }
    },
    {
      name: 'boardWrite',
      path: '/board/write',
      component: () => import('@/views/winner/board/write'),
      meta: {
        gnbSub: false
      }
    }
  ]
}
