<template>
  <!-- <div class="loginmodal">
    <div class="modal-content">
      <h1 class="login-popup-title"><img src="@/assets/img/ace_logo.svg" /></h1>
      <h2>{{ $t('front.login.greeting') }}</h2>
      <ui-input :className="'logininput'" :type="'text'" :placeholder="$t('front.common.memId')" @input="getMemId" />
      <ui-input :className="'logininput'" :type="'password'" :placeholder="$t('front.common.password')" @input="getMemPass" @keyup.enter="onSubmit" />

      <section class="captcha-box">
        <VueClientRecaptcha :value="inputValue" :count="4" chars="12345" :hideLines="true" custom-text-color="black" @getCode="getCaptchaCode" @isValid="checkValidCaptcha">
          <template>
            <span style="color: blue">with Custom Text Or Icon</span>
          </template>
        </VueClientRecaptcha>
        <input v-model="inputValue" placeholder="자동입력방지코드" class="input" type="text" @keyup.enter="onSubmit" />
      </section>
      <ui-button :className="'loginnbtn on'" :text="$t('front.login.loginButton')" @click.prevent="onSubmit" />
      <p class="jointxt">{{ $t('front.login.notFoundId') }}<ui-button :className="'joinnbtn'" :text="$t('front.common.signup')" @click="$emit('close', 'join')" /></p>
      <div class="loginclose" @click="$emit('close')"><img src="@/assets/img/icon_cancel.png" /></div>
    </div>
  </div> -->
  <!-- 로그인 팝업 -->
  <div class="popup_mask" @click="loginClosePopupMask">
    <div class="log_popup">
      <img src="@/assets_mobile/img/winner_mobile/main/Login_form_background_M.png" alt="" />
      <div class="popup_inner" @keyup.enter="onSubmit">
        <p><input type="text" v-model="model.memId" name="mb_id" placeholder="Your ID" @keyup.enter="onSubmit" /></p>
        <p><input type="password" name="mb_password" v-model="model.memPass" placeholder="********" @keyup.enter="onSubmit" /></p>
        <p class="save_id tx"><input type="checkbox" id="save_id" v-model="saveId"><label for="save_id">{{ $t('front.login.saveID') }}</label></p>
        <button type="submit" id="btn-login" class="btn btn-neon btn-neon--2" @click.prevent="onSubmit">Login</button>
      </div>
    </div>
  </div>
</template>

<script>

// import UiButton from '@/components/ui/UiButton'
// import UiInput from '@/components/ui/UiInput'

import { signIn } from '@/api/member'
import { setAuthData, setLoginId, getLoginId, removeLoginId } from '@/libs/auth-helper'
import store from '@/store'

// import VueClientRecaptcha from 'vue-client-recaptcha'

export default {
  name: 'Signin',
  components: {
    // UiButton,
    // UiInput,
    // VueClientRecaptcha
  },
  computed: {
    canSubmit() {
      return this.model.memId && this.model.memPass
    },
    loginId() {
      return getLoginId();
    }
  },
  watch: {
    loginId: {
      handler(loginId) {
        if (loginId) {
          this.saveId = true
          this.model.memId = loginId
        } else this.saveId = false
      },
      immediate: true
    }
  },
  data() {
    return {
      model: {
        memId: '',
        memPass: ''
      },
      data: {
        captchaCode: null,
        isValid: false
      },
      saveId: false,
      inputValue: null,
      runMode: {
        isDev: false,
        siteId: '',
        apiKey: ''
      },
      runModeToggle: false
    }
  },
  methods: {
    loginClosePopupMask(e) {
      const target = e.target
      const filter = target.classList.contains('popup_mask')
      if (filter) {
        this.closeLogin()
      }
    },
    closeLogin() {
      this.$emit('close', 'signin')
    },
    onSubmit() {
      if (this.model.memId === '') {
        this.onAlert('warningart', 'front.signin.emptyMemId')
        return
      }
      if (this.model.memPass === '') {
        this.onAlert('warningart', 'front.signin.emptyMemPass')
        return
      }
      if (!this.canSubmit) {
        return
      }
      signIn(this.model).then(async response => {
        const result = response.data
        const data = result.data

        if (result.resultCode === '200') {
          setAuthData(data)
          store.commit('setUserData', data.member)
          store.dispatch('storeCommonCode').then(commonCode => {
            if (data.msgInfo) {
              store.dispatch('storeUserUnReadMessageCount', data.msgInfo.newMsg)
            }
            if(this.saveId) {
              setLoginId(this.model.memId)
            }else {
              removeLoginId()
            }
            this.closeLogin()
          })
        } else {
          console.log(result.resultCode)
          await this.onCheck('로그인정보가 올바르지 않습니다. 확인해주세요.')
        }
      }).catch(err => {
        console.error('login err : ', err.message)

        if (err.message === 'no member') {
          // alert('로그인정보가 올바르지 않습니다. 확인해주세요.')
          this.onCheck('로그인정보가 올바르지 않습니다. 확인해주세요.')
        } else {
          alert(err.message)
        }
      })
    }
  }
}
</script>
<style>
.save_id {
  padding: 5px 0;
}
</style>
