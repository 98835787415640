export const IS_PRODUCTION = process.env.NODE_ENV === 'production'
export const SOCKET_SERVER_URL = IS_PRODUCTION ? `https://api.${location.host}/websocket` : process.env.VUE_APP_WEB_SOCKET_URL
export const PAGE_TYPE = process.env.VUE_APP_PAGE_TYPE

export const SITE = process.env.VUE_APP_SITE_ID

export const HTTP_HEADER_SKIP_ERROR = 'Winner-Skip-Error'
export const HTTP_STATUS = {
  badRequest: 400,
  unauthrized: 401,
  unprocessableEntity: 422
}

export const STORAGE_ITEM = {
  authToken: 'winner_auth_token'
}

export const AUTH_COOKIE = {
  name: 'winner_auth_token',
  expires: (1 / 1440 * process.env.VUE_APP_LOGIN_TIME) || (1 / 12),
  path: '/'
}

export const LANG_COOKIE = {
  name: 'winner_lang',
  path: '/'
}

export const PARTNER_AUTH_COOKIE = {
  name: SITE + '_auth_token',
  expires: (1 / 1440 * process.env.VUE_APP_LOGIN_TIME) || (1 / 12),
  path: '/'
}

export const BOARD_COOKIE = {
  name: 'winner_noticelist',
  path: '/'
}

export const RUN_MODE = {
  name: 'run_mode'
}

export const SAVE_LOGIN_ID = {
  name: `${process.env.VUE_APP_SITE_ID}_UID`,
  path: '/'
}
// if (IS_PRODUCTION) {
// AUTH_COOKIE.domain = '.naver.com'
// }

export const GAME_CATEGORY = [
  // { name: '전체', icon: require('@/assets/img/all.png'), value: 'all', use: true },
  { name: 'front.gnb.livecasino', icon: require('@/assets/img/live_casino.png'), value: 'casino', use: true },
  { name: 'front.gnb.slot', icon: require('@/assets/img/slot.png'), value: 'slot', use: true },
  { name: 'front.gnb.sport', icon: require('@/assets/img/sports.png'), value: 'sport', use: true },
  { name: 'front.gnb.minigame', icon: require('@/assets/img/minigame.png'), value: 'minigame', use: true }
  //  { name: 'e-스포츠', icon: require('@/assets/img/e_sports.png'), value: 'esport', use: true },

  //  { name: '가상게임', icon: require('@/assets/img/virtualgame.png'), value: 'kiron', use: true }
  // { name: '피싱게임', icon: require('@/assets/img/fishinggame.png'), value: 'fishing', use: true }
  // ,{ name: '로터리게임', icon: require('@/assets/img/rotary.png'), value: 'rotary', use: true }
]
export const GAME_CODE_NAME = {
  kp: '카지노게임',
  kps: '슬롯게임'
}
export const GAME_DETAIL_INFO = {
  evolution: 1,
  biggaming: 2,
  asiagaming: 5,
  dreamgaming: 6,
  sexygaming: 9,
  pragmaticplay: 10,
  playtech: 12,
  hotelcasino: 14,
  slot: 0,
  pragmaticslot: 200,
  hananero: 201,
  elysium: 202,
  fishing: 1000
}

export const SPORT_GAME_CATEGORY = [
  { value: 'all', name: '전체', use: true, default: true, img: require('@/assets/img/all.png') },
  { value: 'soccer', name: '축구', use: true, img: require('@/assets/img/sports.png') },
  { value: 'basketball', name: '농구', use: true, img: require('@/assets/img/basket.png') },
  { value: 'baseball', name: '야구', use: true, img: require('@/assets/img/base.png') },
  { value: 'volleyball', name: '배구', use: true, img: require('@/assets/img/volley.png') },
  { value: 'badminton', name: '배드민턴', use: true, img: require('@/assets/img/tennis.png') },
  { value: 'hockey', name: '하키', use: true, img: require('@/assets/img/hockey2.png') },
  { value: 'combat', name: '격투기', use: true, img: require('@/assets/img/betting.png') }
]

export const SPORT_GAME_CATEGORY2 = [
  { value: 'soccer', name: '축구', use: true, img: require('@/assets/img/sports.png'), imgs: true },
  { value: 'basketball', name: '농구', use: true, img: require('@/assets/img/basket.png'), imgs: true },
  { value: 'baseball', name: '야구', use: true, img: require('@/assets/img/base.png'), imgs: true },
  { value: 'volleyball', name: '배구', use: true, img: require('@/assets/img/volley.png'), imgs: true },
  { value: 'tennis', name: '테니스', use: true, img: require('@/assets/img/tennis.png'), imgs: true },
  { value: 'hockey', name: '하키', use: true, img: require('@/assets/img/hockey2.png'), imgs: true },
  { value: 'handball', name: '핸드볼', use: true, img: require('@/assets/img/handball.png'), imgs: true },
  { value: 'football', name: '미식축구', use: true, img: require('@/assets/img/rugby.png'), imgs: true },
  { value: 'esport', name: 'e-스포츠', use: true, img: require('@/assets/img/e_sports.png'), imgs: true }
]

export const DOCUMENT_TITLE_PREFIX = process.env.VUE_APP_TITLE || ''

export const CASH_STATUS = {
  0: {
    class: 'poss',
    text: '신청'
  },
  1: {
    class: 'blc',
    text: '완료'
  },
  2: {
    class: '',
    text: '대기'
  },
  '-1': {
    class: '',
    text: '취소'
  }
}

export const CASH_STATUS_MOBILE = {
  0: {
    class: 'poss',
    text: '신청'
  },
  1: {
    class: 'done',
    text: '완료'
  },
  2: {
    class: '',
    text: '대기'
  },
  '-1': {
    class: 'cancel',
    text: '취소'
  }
}

export const SOCKET_SUBSCRIBE_DESTINATION = '/topic/'
export const SOCKET_POWERBALL_SUBSCRIBE_DESTINATION = '/topic/powerball/'

export const TRIPLE_GAME_LIST = {
}

export const NonIframeGameCode = [
  '13' // bota
]

export const minigameDisplaySize = {
  x: 1680,
  y: 852
}
export const gameServerUrl = process.env.VUE_APP_FRONT_SERVER

export const PARTNER_LEVEL = {
  PTN_1: '총본사',
  PTN_2: '대본사',
  PTN_3: '부본사',
  PTN_4: '총판',
  PTN_5: '매장',
  NORMAL: '회원'
}

export const PARTNER_LEVEL_ORD = {
  PTN_1: 1,
  PTN_2: 2,
  PTN_3: 3,
  PTN_4: 4,
  PTN_5: 5,
  NORMAL: 6
}

export const MARKET_LIST = {
  soccer: [
    {
      gameType: 'winLose',
      market_id: '10001',
      description: '승무패',
      betTypes: 'Home, Draw, Away'
    },
    {
      gameType: 'special',
      market_id: '10002',
      description: '더블찬스',
      betTypes: 'Home/Draw, Home/Away, Draw/Away'
    },
    {
      gameType: 'special',
      market_id: '10003',
      description: '양팀모두득점',
      betTypes: 'Yes, No'
    },
    {
      gameType: 'winLose',
      market_id: '10004',
      description: '승패',
      betTypes: 'Home, Away'
    },
    {
      gameType: 'special',
      market_id: '10005',
      description: '정확한 스코어',
      betTypes: 'Home, Draw, Away'
    },
    {
      gameType: 'special',
      market_id: '10006',
      description: '하프타임/풀타임',
      betTypes: 'Home/Home, Home/Draw, Home/Away, Draw/Home, Draw/Draw, Draw/Away, Away/Home, Away/Draw, Away/Away'
    },
    {
      gameType: 'special',
      market_id: '10007',
      description: '승무패/양팀모두득점',
      betTypes: 'Home/Yes, Home/No, Draw/Yes, Draw/No, Away/Yes, Away/No'
    },
    {
      gameType: 'handicap',
      market_id: '10008',
      description: '핸디캡',
      betTypes: 'Home, Away'
    },
    {
      gameType: 'handicap',
      market_id: '10009',
      description: '핸디캡',
      betTypes: 'Home, Away'
    },
    {
      gameType: 'handicap',
      market_id: '10010',
      description: '핸디캡',
      betTypes: 'Home, Away'
    },
    {
      gameType: 'special',
      market_id: '10011',
      description: 'Asian Lines 언더오버',
      betTypes: 'Under, Over'
    },
    {
      gameType: 'special',
      market_id: '10012',
      description: 'Asian Lines 언더오버',
      betTypes: 'Under, Over'
    },
    {
      gameType: 'special',
      market_id: '10013',
      description: 'Goals 언더오버',
      betTypes: 'Under, Over'
    },
    {
      gameType: 'special',
      market_id: '10014',
      description: 'Goals 언더오버',
      betTypes: 'Under, Over'
    },
    {
      gameType: 'underOver',
      market_id: '10015',
      description: '언더오버',
      betTypes: 'Under, Over'
    },
    {
      gameType: 'special',
      market_id: '10018',
      description: '전반전 핸디캡',
      betTypes: 'Home, Away'
    },
    {
      gameType: 'special',
      market_id: '10024',
      description: '홀짝',
      betTypes: 'Odd, Even'
    },
    {
      gameType: 'special',
      market_id: '10025',
      description: '전반전 양팀모두득점',
      betTypes: 'Yes, No'
    },
    {
      gameType: 'special',
      market_id: '10025',
      description: '후반전 양팀모두득점',
      betTypes: 'Yes, No'
    },
    {
      gameType: 'special',
      market_id: '10027',
      description: '전반전 언더오버',
      betTypes: 'Under, Over'
    },
    {
      gameType: 'special',
      market_id: '10028',
      description: '후반전 언더오버',
      betTypes: 'Under, Over'
    },
    {
      gameType: 'special',
      market_id: '10029',
      description: '승무패/언더오버',
      betTypes: 'Home/Under, Home/Over, Draw/Under, Draw/Over, Away/Under, Away/Over'
    },
    {
      gameType: 'special',
      market_id: '10030',
      description: '언더오버/양팀모두득점',
      betTypes: 'Under/Yes, Over/Yes, Under/No, Over/No'
    },
    {
      gameType: 'special',
      market_id: '10031',
      description: '홈/언더오버',
      betTypes: 'Under, Over'
    },
    {
      gameType: 'special',
      market_id: '10032',
      description: '원정/언더오버',
      betTypes: 'Under, Over'
    },
    {
      gameType: 'special',
      market_id: '10033',
      description: '전반전 승무패',
      betTypes: 'Home, Draw, Away'
    },
    {
      gameType: 'special',
      market_id: '10034',
      description: '전반전 더블찬스',
      betTypes: 'Home/Draw, Home/Away, Draw/Away'
    },
    {
      gameType: 'special',
      market_id: '10035',
      description: '전반전 정확한스코어',
      betTypes: 'Home, Draw, Away'
    },
    {
      gameType: 'special',
      market_id: '10036',
      description: '전반전 핸디캡',
      betTypes: 'Home, Away'
    },
    {
      gameType: 'special',
      market_id: '10037',
      description: '전반전 Asian Lines 언더오버',
      betTypes: 'Under, Over'
    },
    {
      gameType: 'special',
      market_id: '10038',
      description: '정확한 골',
      betTypes: 'Goal'
    },
    {
      gameType: 'special',
      market_id: '10039',
      description: '전반전 정확한 골',
      betTypes: 'Goal'
    },
    {
      gameType: 'special',
      market_id: '10040',
      description: '후반전 정확한 골',
      betTypes: 'Goal'
    },
    {
      gameType: 'special',
      market_id: '10041',
      description: '전반전 홀짝',
      betTypes: 'Odd, Even'
    },
    {
      gameType: 'special',
      market_id: '10042',
      description: '후반전 홀짝',
      betTypes: 'Odd, Even'
    },
    {
      gameType: 'special',
      market_id: '10043',
      description: '후반전 승무패',
      betTypes: 'Home, Draw, Away'
    },
    {
      gameType: 'special',
      market_id: '10045',
      description: '전반전 승무패/언더오버',
      betTypes: 'Home/Under, Home/Over, Draw/Under, Draw/Over, Away/Under, Away/Over'
    },
    {
      gameType: 'special',
      market_id: '10046',
      description: '전반전 승무패/양팀모두득점',
      betTypes: 'Home/Yes, Home/No, Draw/Yes, Draw/No, Away/Yes, Away/No'
    },
    {
      gameType: 'special',
      market_id: '10047',
      description: '첫 득점 팀',
      betTypes: 'Home, Draw, Away'
    },
    {
      gameType: 'special',
      market_id: '10048',
      description: '마지막 득점 팀',
      betTypes: 'Home, Draw, Away'
    },
    {
      gameType: 'special',
      market_id: '10049',
      description: '득점 성공팀',
      betTypes: 'Both Teams, Home Only, No Goal, Away Only'
    },
    {
      gameType: 'special',
      market_id: '10050',
      description: '핸디캡 승무패',
      betTypes: 'Home, Draw, Away'
    },
    {
      gameType: 'special',
      market_id: '10051',
      description: '핸디캡 승무패',
      betTypes: 'Home, Draw, Away'
    },
    {
      gameType: 'special',
      market_id: '10052',
      description: '전반전 Asian Lines 언더오버',
      betTypes: 'Under, Over'
    }
  ],
  football: [
    {
      gameType: 'winLose',
      market_id: '11001',
      description: '승패',
      betTypes: 'Home, Away'
    },
    {
      gameType: 'special',
      market_id: '11002',
      description: '연장제외 핸디캡',
      betTypes: 'Home, Away'
    },
    {
      gameType: 'special',
      market_id: '11003',
      description: '연장제외 언더오버',
      betTypes: 'Under, Over'
    },
    {
      gameType: 'special',
      market_id: '11004',
      description: '전반전 승패',
      betTypes: 'Home, Away'
    },
    {
      gameType: 'special',
      market_id: '11005',
      description: '전반전 핸디캡',
      betTypes: 'Home, Away'
    },
    {
      gameType: 'special',
      market_id: '11006',
      description: '전반전 언더오버',
      betTypes: 'Under, Over'
    },
    {
      gameType: 'special',
      market_id: '11007',
      description: '1쿼터 승패',
      betTypes: 'Home, Away'
    },
    {
      gameType: 'special',
      market_id: '11008',
      description: '1쿼터 핸디캡',
      betTypes: 'Home, Away'
    },
    {
      gameType: 'special',
      market_id: '11009',
      description: '1쿼터 언더오버',
      betTypes: 'Under, Over'
    }
  ],
  boxing: [
    {
      gameType: 'winLose',
      market_id: '12001',
      description: '승패',
      betTypes: 'Home, Away'
    }
  ],
  tennis: [
    {
      gameType: 'winLose',
      market_id: '13001',
      description: '승패',
      betTypes: 'Home, Away'
    },
    {
      gameType: 'handicap',
      market_id: '13002',
      description: '핸디캡',
      betTypes: 'Home, Away'
    },
    {
      gameType: 'special',
      market_id: '13003',
      description: '매치 정확한 세트 결과',
      betTypes: 'Home, Away'
    },
    {
      gameType: 'special',
      market_id: '13004',
      description: '1세트 승패',
      betTypes: 'Home, Away'
    },
    {
      gameType: 'special',
      market_id: '13005',
      description: '1세트 정환학 스코어',
      betTypes: 'Home, Away'
    },
    {
      gameType: 'special',
      market_id: '13006',
      description: '1세트 언더오버',
      betTypes: 'Under, Over'
    },
    {
      gameType: 'underOver',
      market_id: '13007',
      description: '언더오버',
      betTypes: 'Under, Over'
    }
  ],
  baseball: [
    {
      gameType: 'winLose',
      market_id: '14001',
      description: '승패',
      betTypes: 'Home, Away'
    },
    {
      gameType: 'special',
      market_id: '14002',
      description: '연장제외 핸디캡',
      betTypes: 'Home, Away'
    },
    {
      gameType: 'special',
      market_id: '14003',
      description: '연장제외 언더오버',
      betTypes: 'Under, Over'
    },
    {
      gameType: 'special',
      market_id: '14004',
      description: '홀짝',
      betTypes: 'Odd, Even'
    },
    {
      gameType: 'special',
      market_id: '14005',
      description: '연장제외 핸디캡',
      betTypes: 'Home, Away'
    },
    {
      gameType: 'special',
      market_id: '14006',
      description: '연장제외 언더오버',
      betTypes: 'Under, Over'
    },
    {
      gameType: 'special',
      market_id: '14007',
      description: '1이닝 승무패',
      betTypes: 'Home, Draw, Away'
    },
    {
      gameType: 'special',
      market_id: '14008',
      description: '3이닝 합계 핸디캡',
      betTypes: 'Home, Away'
    },
    {
      gameType: 'special',
      market_id: '14009',
      description: '3이닝 합계 언더오버',
      betTypes: 'Under, Over'
    },
    {
      gameType: 'special',
      market_id: '14010',
      description: '3이닝 합계 핸디캡',
      betTypes: 'Home, Away'
    },
    {
      gameType: 'special',
      market_id: '14011',
      description: '3이닝 합계 언더오버',
      betTypes: 'Under, Over'
    },
    {
      gameType: 'special',
      market_id: '14012',
      description: '5이닝 합계 핸디캡',
      betTypes: 'Home, Away'
    },
    {
      gameType: 'special',
      market_id: '14013',
      description: '5이닝 합계 언더오버',
      betTypes: 'Under, Over'
    },
    {
      gameType: 'special',
      market_id: '14014',
      description: '5이닝 합계 핸디캡',
      betTypes: 'Home, Away'
    },
    {
      gameType: 'special',
      market_id: '14015',
      description: '5이닝 합계 언더오버',
      betTypes: 'Under, Over'
    },
    {
      gameType: 'special',
      market_id: '14016',
      description: '7이닝 합계 핸디캡',
      betTypes: 'Home, Away'
    },
    {
      gameType: 'special',
      market_id: '14017',
      description: '7이닝 합계 언더오버',
      betTypes: 'Under, Over'
    },
    {
      gameType: 'special',
      market_id: '14018',
      description: '7이닝 합계 핸디캡',
      betTypes: 'Home, Away'
    },
    {
      gameType: 'special',
      market_id: '14019',
      description: '7이닝 합계 언더오버',
      betTypes: 'Under, Over'
    },
    {
      gameType: 'special',
      market_id: '14020',
      description: '1이닝 Top 득점',
      betTypes: 'Yes, No'
    },
    {
      gameType: 'special',
      market_id: '14021',
      description: '1이닝 Bottom 득점',
      betTypes: 'Yes, No'
    },
    {
      gameType: 'special',
      market_id: '14022',
      description: '1이닝 득점',
      betTypes: 'Yes, No'
    },
    {
      gameType: 'special',
      market_id: '14023',
      description: '연장제외 핸디캡',
      betTypes: 'Home, Away'
    },
    {
      gameType: 'special',
      market_id: '14024',
      description: '5이닝 합계 핸디캡',
      betTypes: 'Home, Away'
    },
    {
      gameType: 'special',
      market_id: '14025',
      description: '연장제외 언더오버',
      betTypes: 'Under, Over'
    },
    {
      gameType: 'special',
      market_id: '14026',
      description: '투수 볼넷 허용',
      betTypes: 'Under, Over (name2,player_id,player_name,team 항목 존재)'
    }
  ],
  icehockey: [
    {
      gameType: 'winLose',
      market_id: '15001',
      description: '승패',
      betTypes: 'Home, Away'
    },
    {
      gameType: 'handicap',
      market_id: '15002',
      description: '핸디캡',
      betTypes: 'Home, Away'
    },
    {
      gameType: 'underOver',
      market_id: '15003',
      description: '언더오버',
      betTypes: 'Under, Over'
    },
    {
      gameType: 'winLose',
      market_id: '15004',
      description: '승무패',
      betTypes: 'Home, Draw, Away'
    },
    {
      gameType: 'special',
      market_id: '15005',
      description: '핸디캡 3-Way',
      betTypes: 'Home, Draw, Away'
    },
    {
      gameType: 'special',
      market_id: '15006',
      description: '언더오버 3-Way',
      betTypes: 'Home, Draw, Away'
    },
    {
      gameType: 'special',
      market_id: '15007',
      description: '더블찬스',
      betTypes: 'Home/Draw, Home/Away, Draw/Away'
    },
    {
      gameType: 'special',
      market_id: '15008',
      description: '정확한스코어',
      betTypes: 'Home, Away'
    },
    {
      gameType: 'special',
      market_id: '15009',
      description: '홀짝',
      betTypes: 'Odd, Even'
    },
    {
      gameType: 'special',
      market_id: '15010',
      description: '매치 종료 시점',
      betTypes: 'Regulation, Overtime, Shootout'
    },
    {
      gameType: 'handicap',
      market_id: '15011',
      description: '핸디캡',
      betTypes: 'Home, Away'
    },
    {
      gameType: 'special',
      market_id: '15012',
      description: '언더오버',
      betTypes: 'Under, Over'
    },
    {
      gameType: 'special',
      market_id: '15013',
      description: '1피리어드 승패',
      betTypes: 'Home, Away'
    },
    {
      gameType: 'special',
      market_id: '15014',
      description: '1피리어드 핸디캡',
      betTypes: 'Home, Away'
    },
    {
      gameType: 'special',
      market_id: '15015',
      description: '1피리어드 언더오버',
      betTypes: 'Under, Over'
    },
    {
      gameType: 'special',
      market_id: '15016',
      description: '1피리어드 승무패',
      betTypes: 'Home, Draw, Away'
    },
    {
      gameType: 'special',
      market_id: '15017',
      description: '1피리어드 더블찬스',
      betTypes: 'Home/Draw, Home/Away, Draw/Away'
    },
    {
      gameType: 'special',
      market_id: '15018',
      description: '1피리어드 핸디캡',
      betTypes: 'Home, Away'
    },
    {
      gameType: 'special',
      market_id: '15019',
      description: '1피리어드 언더오버',
      betTypes: 'Under, Over'
    },
    {
      gameType: 'special',
      market_id: '15051',
      description: '아시안 핸디캡',
      betTypes: 'Home, Away'
    },
    {
      gameType: 'special',
      market_id: '15052',
      description: '아시안 언더오버',
      betTypes: 'Under, Over'
    },
    {
      gameType: 'special',
      market_id: '15053',
      description: '아시안 핸디캡',
      betTypes: 'Home, Away'
    },
    {
      gameType: 'special',
      market_id: '15054',
      description: '아시안 언더오버',
      betTypes: 'Under, Over'
    }
  ],
  basketball: [
    {
      gameType: 'winLose',
      market_id: '16001',
      description: '승패',
      betTypes: 'Home, Away'
    },
    {
      gameType: 'special',
      market_id: '16002',
      description: '연장제외 핸디캡',
      betTypes: 'Home, Away'
    },
    {
      gameType: 'special',
      market_id: '16003',
      description: '연장제외 언더오버',
      betTypes: 'Under, Over'
    },
    {
      gameType: 'special',
      market_id: '16004',
      description: '전반전 승패',
      betTypes: 'Home, Away'
    },
    {
      gameType: 'special',
      market_id: '16005',
      description: '전반전 핸디캡',
      betTypes: 'Home, Away'
    },
    {
      gameType: 'special',
      market_id: '16006',
      description: '전반전 언더오버',
      betTypes: 'Under, Over'
    },
    {
      gameType: 'special',
      market_id: '16007',
      description: '1쿼터 승패',
      betTypes: 'Home, Away'
    },
    {
      gameType: 'special',
      market_id: '16008',
      description: '1쿼터 핸디캡',
      betTypes: 'Home, Away'
    },
    {
      gameType: 'special',
      market_id: '16009',
      description: '1쿼터 언더오버',
      betTypes: 'Under, Over'
    },
    {
      gameType: 'special',
      market_id: '16010',
      description: '1쿼터 승무패',
      betTypes: 'Home, Draw, Away'
    },
    {
      gameType: 'special',
      market_id: '16011',
      description: '2쿼터 승무패',
      betTypes: 'Home, Draw, Away'
    },
    {
      gameType: 'special',
      market_id: '16012',
      description: '3쿼터 승무패',
      betTypes: 'Home, Draw, Away'
    },
    {
      gameType: 'special',
      market_id: '16013',
      description: '4쿼터 승무패',
      betTypes: 'Home, Draw, Away'
    },
    {
      gameType: 'special',
      market_id: '16014',
      description: '2쿼터 핸디캡',
      betTypes: 'Home, Away'
    },
    {
      gameType: 'special',
      market_id: '16015',
      description: '2쿼터 언더오버',
      betTypes: 'Under, Over'
    },
    {
      gameType: 'special',
      market_id: '16016',
      description: '3쿼터 핸디캡',
      betTypes: 'Home, Away'
    },
    {
      gameType: 'special',
      market_id: '16017',
      description: '3쿼터 언더오버',
      betTypes: 'Under, Over'
    },
    {
      gameType: 'special',
      market_id: '16018',
      description: '4쿼터 핸디캡',
      betTypes: 'Home, Away'
    },
    {
      gameType: 'special',
      market_id: '16019',
      description: '4쿼터 언더오버',
      betTypes: 'Under, Over'
    },
    {
      gameType: 'special',
      market_id: '16020',
      description: '연장제외 핸디캡',
      betTypes: 'Home, Away'
    },
    {
      gameType: 'special',
      market_id: '16021',
      description: '연장제외 언더오버',
      betTypes: 'Under, Over'
    },
    {
      gameType: 'special',
      market_id: '16022',
      description: '밴드별 점수 3-Way',
      betTypes: 'Total'
    },
    {
      gameType: 'special',
      market_id: '16023',
      description: '밴드별 점수 8-Way',
      betTypes: 'Total'
    },
    {
      gameType: 'special',
      market_id: '16024',
      description: '선수 득점',
      betTypes: 'Under, Over (name2,player_id,player_name,team 항목 존재)'
    }
  ],
  handball: [
    {
      gameType: 'winLose',
      market_id: '17001',
      description: '승무패',
      betTypes: 'Home, Draw, Away'
    },
    {
      gameType: 'handicap',
      market_id: '17002',
      description: '핸디캡',
      betTypes: 'Home, Away'
    },
    {
      gameType: 'underOver',
      market_id: '17003',
      description: '언더오버',
      betTypes: 'Under, Over'
    }
  ],
  volleyball: [
    {
      gameType: 'winLose',
      market_id: '18001',
      description: '승패',
      betTypes: 'Home, Away'
    },
    {
      gameType: 'handicap',
      market_id: '18002',
      description: '핸디캡',
      betTypes: 'Home, Away'
    },
    {
      gameType: 'underOver',
      market_id: '18003',
      description: '언더오버',
      betTypes: 'Under, Over'
    },
    {
      gameType: 'special',
      market_id: '18004',
      description: '홀짝',
      betTypes: 'Odd, Even'
    },
    {
      gameType: 'special',
      market_id: '18005',
      description: '정확한 세트스코어',
      betTypes: 'Home, Away'
    },
    {
      gameType: 'special',
      market_id: '18007',
      description: '홈/언더오버',
      betTypes: 'Under, Over'
    },
    {
      gameType: 'special',
      market_id: '18008',
      description: '원정/언더오버',
      betTypes: 'Under, Over'
    },
    {
      gameType: 'special',
      market_id: '18009',
      description: '1세트 승패',
      betTypes: 'Home, Away'
    },
    {
      gameType: 'special',
      market_id: '18010',
      description: '1세트 핸디캡',
      betTypes: 'Home, Away'
    },
    {
      gameType: 'special',
      market_id: '18011',
      description: '1세트 언더오버',
      betTypes: 'Under, Over'
    },
    {
      gameType: 'special',
      market_id: '18012',
      description: '1세트 홀짝',
      betTypes: 'Odd, Even'
    },
    {
      gameType: 'special',
      market_id: '18013',
      description: '1세트 듀스 여부',
      betTypes: 'Yes, No'
    },
    {
      gameType: 'special',
      market_id: '18014',
      description: '1세트 정확한스코어',
      betTypes: 'Home, Away'
    },
    {
      gameType: 'special',
      market_id: '18057',
      description: '포인트 핸디캡',
      betTypes: 'Home, Away'
    }
  ],
  tabletennis: [
    {
      gameType: 'winLose',
      market_id: '19001',
      description: '승패',
      betTypes: 'Home, Away'
    }
  ],
  esports: [
    {
      gameType: 'winLose',
      market_id: '20001',
      description: '승패',
      betTypes: 'Home, Away'
    },
    {
      gameType: 'handicap',
      market_id: '20002',
      description: '핸디캡',
      betTypes: 'Home, Away'
    },
    {
      gameType: 'underOver',
      market_id: '20003',
      description: '언더오버',
      betTypes: 'Home, Away'
    },
    {
      gameType: 'special',
      market_id: '20004',
      description: '1세트 첫용',
      betTypes: 'Home, Away'
    },
    {
      gameType: 'special',
      market_id: '20005',
      description: '1세트 첫바론',
      betTypes: 'Home, Away'
    },
    {
      gameType: 'special',
      market_id: '20006',
      description: '1세트 첫타워',
      betTypes: 'Home, Away'
    },
    {
      gameType: 'special',
      market_id: '20007',
      description: '1세트 첫킬',
      betTypes: 'Home, Away'
    },
    {
      gameType: 'special',
      market_id: '20008',
      description: '킬 1세트 핸디캡',
      betTypes: 'Home, Away'
    },
    {
      gameType: 'special',
      market_id: '20009',
      description: '킬 1세트 언더오버',
      betTypes: 'Under, Over'
    },
    {
      gameType: 'special',
      market_id: '20010',
      description: '1세트 승패',
      betTypes: 'Home, Away'
    },
    {
      gameType: 'special',
      market_id: '20011',
      description: '경기시간 1세트 언더오버',
      betTypes: 'Over or Exactly, Under'
    }
  ]
}
export const MARKET_LIST_SPECIAL = {
  soccer: [
    {
      gameType: 'winLose',
      market_id: '10033',
      description: '승무패',
      betTypes: 'Home, Draw, Away'
    }
  ],
  baseball: [
    {
      gameType: 'handicap',
      market_id: '14012',
      description: '5이닝 합계 핸디캡',
      betTypes: 'Home, Away'
    },
    {
      gameType: 'underOver',
      market_id: '14013',
      description: '5이닝 합계 언더오버',
      betTypes: 'Under, Over'
    }
  ],
  icehockey: [
    {
      gameType: 'handicap',
      market_id: '15014',
      description: '1피리어드 핸디캡',
      betTypes: 'Home, Away'
    },
    {
      gameType: 'underOver',
      market_id: '15015',
      description: '1피리어드 언더오버',
      betTypes: 'Under, Over'
    }
  ],
  basketball: [
    {
      gameType: 'handicap',
      market_id: '16008',
      description: '1쿼터 핸디캡',
      betTypes: 'Home, Away'
    },
    {
      gameType: 'underOver',
      market_id: '16009',
      description: '1쿼터 언더오버',
      betTypes: 'Under, Over'
    }
  ],
  volleyball: [
    {
      gameType: 'handicap',
      market_id: '18010',
      description: '1세트 핸디캡',
      betTypes: 'Home, Away'
    },
    {
      gameType: 'underOver',
      market_id: '18011',
      description: '1세트 언더오버',
      betTypes: 'Under, Over'
    }
  ]
}
export const MARKET_LIST_SINGLE = {
  soccer: [
    {
      gameType: 'winLose',
      market_id: '10001',
      description: '승무패',
      betTypes: 'Home, Draw, Away'
    },
    {
      gameType: 'handicap',
      market_id: '10008',
      description: '핸디캡',
      betTypes: 'Home, Away'
    },
    {
      gameType: 'underOver',
      market_id: '10011',
      description: 'Asian Lines 언더오버',
      betTypes: 'Under, Over'
    },
    {
      gameType: 'underOver',
      market_id: '10013',
      description: 'Goals 언더오버',
      betTypes: 'Under, Over'
    },
    {
      gameType: 'underOver',
      market_id: '10015',
      description: '언더오버',
      betTypes: 'Under, Over'
    }
  ],
  americanfootball: [
    {
      gameType: 'winLose',
      market_id: '11001',
      description: '승패',
      betTypes: 'Home, Away'
    },
    {
      gameType: 'handicap',
      market_id: '11002',
      description: '연장제외 핸디캡',
      betTypes: 'Home, Away'
    },
    {
      gameType: 'underOver',
      market_id: '11003',
      description: '연장제외 언더오버',
      betTypes: 'Under, Over'
    }
  ],
  boxing: [
    {
      gameType: 'winLose',
      market_id: '12001',
      description: '승패',
      betTypes: 'Home, Away'
    }
  ],
  tennis: [
    {
      gameType: 'winLose',
      market_id: '13001',
      description: '승패',
      betTypes: 'Home, Away'
    },
    {
      gameType: 'handicap',
      market_id: '13002',
      description: '핸디캡',
      betTypes: 'Home, Away'
    },
    {
      gameType: 'underOver',
      market_id: '13007',
      description: '언더오버',
      betTypes: 'Under, Over'
    }
  ],
  baseball: [
    {
      gameType: 'winLose',
      market_id: '14001',
      description: '승패',
      betTypes: 'Home, Away'
    },
    {
      gameType: 'handicap',
      market_id: '14002',
      description: '연장제외 핸디캡',
      betTypes: 'Home, Away'
    },
    {
      gameType: 'underOver',
      market_id: '14003',
      description: '연장제외 언더오버',
      betTypes: 'Under, Over'
    }
  ],
  icehockey: [
    {
      gameType: 'winLose',
      market_id: '15004',
      description: '승무패',
      betTypes: 'Home, Away'
    },
    {
      gameType: 'handicap',
      market_id: '15002',
      description: '핸디캡',
      betTypes: 'Home, Away'
    },
    {
      gameType: 'underOver',
      market_id: '15003',
      description: '언더오버',
      betTypes: 'Under, Over'
    }
  ],
  basketball: [
    {
      gameType: 'winLose',
      market_id: '16001',
      description: '승패',
      betTypes: 'Home, Away'
    },
    {
      gameType: 'handicap',
      market_id: '16002',
      description: '연장제외 핸디캡',
      betTypes: 'Home, Away'
    },
    {
      gameType: 'underOver',
      market_id: '16003',
      description: '연장제외 언더오버',
      betTypes: 'Under, Over'
    }
  ],
  handball: [
    {
      gameType: 'winLose',
      market_id: '17001',
      description: '승무패',
      betTypes: 'Home, Draw, Away'
    },
    {
      gameType: 'handicap',
      market_id: '17002',
      description: '핸디캡',
      betTypes: 'Home, Away'
    },
    {
      gameType: 'underOver',
      market_id: '17003',
      description: '언더오버',
      betTypes: 'Under, Over'
    }
  ],
  volleyball: [
    {
      gameType: 'winLose',
      market_id: '18001',
      description: '승패',
      betTypes: 'Home, Away'
    },
    {
      gameType: 'handicap',
      market_id: '18002',
      description: '핸디캡',
      betTypes: 'Home, Away'
    },
    {
      gameType: 'underOver',
      market_id: '18003',
      description: '언더오버',
      betTypes: 'Under, Over'
    }
  ],
  tabletennis: [
    {
      gameType: 'winLose',
      market_id: '19001',
      description: '승패',
      betTypes: 'Home, Away'
    }
  ],
  esports: [
    {
      gameType: 'winLose',
      market_id: '20001',
      description: '승패',
      betTypes: 'Home, Away'
    },
    {
      gameType: 'handicap',
      market_id: '20002',
      description: '핸디캡',
      betTypes: 'Home, Away'
    },
    {
      gameType: 'underOver',
      market_id: '20003',
      description: '언더오버',
      betTypes: 'Home, Away'
    }
  ]
}

export const SPORTS_SPECIAL_LIST = ['baseball', 'icehockey', 'basketball', 'volleyball', 'esports']
